import { useAuth } from "../../auth/auth-context";
import { v4 as uuidv4 } from "uuid";
import * as Yup from "yup";
import { cookingParameterConstant, difficultyLevel } from "../constants";

const getPlainTextLength = text => 
  text ? text.replace(/@\[(.*?)\]\((.*?)\)/g, (_, displayText) => displayText).trim().length : 0;

const createValidationSchema = (getPlainTextLength) => {
  const commonValidations = {
    alphanumeric: /^[a-zA-Z0-9\s]*$/,
    alphanumericWithPunctuation: /^[a-zA-Z0-9\s,.\-\/"']*$/,
    messages: {
      alphanumeric: "Only alphanumeric characters allowed",
      alphanumericWithPunctuation: "Only alphanumeric characters, commas and periods allowed"
    }
  };

  const temperatureValidation = Yup.number()
    .typeError("Must be a number")
    .min(40, "Minimum 40 °C.")
    .max(200, "Maximum 200 °C.")
    .transform((value, originalValue) => originalValue === "" ? undefined : Number(originalValue));

  const createTimeValidation = (required = true) => Yup.number()
    .when("isDraftRecipe", {
      is: false,
      then: schema => schema
        .min(0, "Minimum 0 minutes")
        .max(999, "Max 999 minutes")
        .typeError("Must be a number")
        .required(required ? "Time is required" : undefined),
      otherwise: schema => schema.nullable(true)
    });

  return Yup.object().shape({
    isDraftRecipe: Yup.boolean(),
    isRecipeProSelected: Yup.boolean(),
    isRikuSelected: Yup.boolean(),
    isSemiSelected: Yup.boolean(),

    metadata: Yup.object().shape({
      title: Yup.string()
        .max(60, "Maximum 60 characters")
        .required("Recipe title cannot be empty"),
        // .matches(commonValidations.alphanumeric, commonValidations.messages.alphanumeric),
      
      isDraftRecipe: Yup.boolean(),
      
      recipeDescription: Yup.string().when("isDraftRecipe", {
        is: false,
        then: schema => schema
          .max(500, "Cannot exceed 500 characters")
          .required("Recipe description cannot be empty")
          // .matches(commonValidations.alphanumericWithPunctuation, commonValidations.messages.alphanumericWithPunctuation)
      }),

      recipeServeDescription: Yup.string().when("isDraftRecipe", {
        is: false,
        then: schema => schema
          // .matches(commonValidations.alphanumericWithPunctuation, commonValidations.messages.alphanumericWithPunctuation)
          .max(250, "Cannot exceed 250 characters")
          .required("Please describe how to serve the recipe")
      }),

      thumbnailUrl: Yup.string().when("isDraftRecipe", {
        is: false,
        then: schema => schema.required("Image is required")
      }),

      prepTimeInMins: createTimeValidation(),
      cookTimeInMins: createTimeValidation(),

      tools: Yup.array().when("isDraftRecipe", {
        is: false,
        then: schema => schema
          .min(1, "At least one tool is required")
          .required("Tools field is required")
      }),

      courses: Yup.array().when("isDraftRecipe", {
        is: false,
        then: schema => schema
          .required("Courses field is required")
          .min(1, "At least one course is required")
          .max(3, "Max 3 courses can be selected")
      }),

      diets: Yup.array().when("isDraftRecipe", {
        is: false,
        then: schema => schema
          .min(1, "At least one diet is required")
          .required("Diets field is required")
      }),

      cuisines: Yup.array().when("isDraftRecipe", {
        is: false,
        then: schema => schema
          .min(1, "At least one cuisine is required")
          .required("Cuisines field is required")
      })
    }),

    recipeIngredients: Yup.array().when(
      ["metadata.isDraftRecipe", "isLoopIngredient"],
      ([isDraftRecipe], schema) => {
        if (!isDraftRecipe) {
          return schema.of(
            Yup.object().shape({
              name: Yup.string().required("Ingredient name is required"),
              instructionId: Yup.string().required("Ingredient is not added in step"),
              quantity: Yup.number()
                .moreThan(0, "Must be greater than 0")
                .max(99999, "Max 5 characters")
                .typeError("Must be a number")
                .required("Required field"),
              units: Yup.string().required("Units are required"),
              unitsPerStep: Yup.string().when("isLoopIngredient", {
                is: true,
                then: (schema) => schema.required("units Per Step is required")
              }),
              quantityPerStep: Yup.number()
                .when("isLoopIngredient", {
                  is: true,
                  then: (schema) => schema
                    .required("Required field")
                    .moreThan(0, "Must be greater than 0")
                    .max(99999, "Max 5 characters")
                    .typeError("Must be a number")
                })
                .transform((value, originalValue) => originalValue === null ? undefined : Number(originalValue))
            })
          );
        }
      }
    ),

    recipeSections: Yup.array().when(
      ["metadata.isDraftRecipe", "isLoopSection"],
      ([isDraftRecipe], schema) => {
        if (!isDraftRecipe) {
          return schema.of(
            Yup.object().shape({
              sectionName: Yup.string()
                .max(60, "Maximum 60 characters")
                .required("Recipe section is required"),
              loopInstruction: Yup.string().when("isLoopSection", {
                is: true,
                then: (schema) => schema
                  .max(60, "Maximum 60 characters")
                  .required("Loop instruction is required")
              })
            })
          );
        }
      }
    ),

    recipeInstructions: Yup.array().when(
      ["metadata.isDraftRecipe", "metadata.productReleases"],
      ([isDraftRecipe, productReleases], schema) => {
        if (!isDraftRecipe) {
          return schema.of(
            Yup.object().shape({
              mentionsTitle: Yup.string()
                .required("This step instruction is required")
                .test("max-visible-length", "Maximum characters limit exceed",
                  value => getPlainTextLength(value) <= (productReleases?.length > 0 ? 60 : 160)
                ),
              tip: Yup.string().max(250, "Maximum 250 characters")
            })
          );
        }
      }
    ),

    cookingParameters: Yup.array().when(
      ["metadata.isDraftRecipe", "metadata.productReleases", "action"],
      ([isDraftRecipe, productReleases, action], schema) => {
        if (!isDraftRecipe) {
          return schema.of(
            Yup.object().shape({
              centralTemperatureInC: Yup.number()
                .when(["action", "isCentralProbeTemperatureRequired"], {
                  is: (action, isCentralProbeTemperatureRequired) =>
                    action !== "" &&
                    isCentralProbeTemperatureRequired === "REQUIRED",
                  then: (schema) =>
                    schema
                      .required("Required")
                      .typeError("Must be a number")
                      .min(40, "Minimum 40 °C.")
                      .max(200, "Maximum 200 °C."),
                  otherwise: (schema) => schema,
                })
                .transform((value, originalValue) => {
                  return originalValue === ""
                    ? undefined
                    : Number(originalValue);
                }),
              externalTemperatureInC: Yup.number()
                .when(["action", "isExternalProbeTemperatureRequired"], {
                  is: (action, isExternalProbeTemperatureRequired) =>
                    action !== "" &&
                    isExternalProbeTemperatureRequired === "REQUIRED",
                  then: (schema) =>
                    schema
                      .required("Required")
                      .typeError("Must be a number")
                      .min(40, "Minimum 40 °C.")
                      .max(200, "Maximum 200 °C."),
                  otherwise: (schema) => schema,
                })
                .transform((value, originalValue) => {
                  return originalValue === ""
                    ? undefined
                    : Number(originalValue);
                }),
              powerInLevel: Yup.number()
                .when(["action", "isPowerRequired"], {
                  is: (action, required) => action !== "" && required === "REQUIRED",
                  then: (schema) => schema
                    .required("Required")
                    .typeError("Must be a number")
                    .min(1, "Minimum 1 level")
                    .max(10, "Maximum 10 level")
                })
                .transform((value, originalValue) => originalValue === "" ? undefined : Number(originalValue)),
              durationInMins: Yup.number()
                .when(["action", "isTimeRequired"], {
                  is: (action, required) => action !== "" && required === "REQUIRED",
                  then: (schema) => schema
                    .required("Required")
                    .typeError("Must be a number")
                    .min(0.25, "Minimum 0.25 minute")
                    .max(500, "Maximum 500 minutes")
                })
                .transform((value, originalValue) => originalValue === "" ? undefined : Number(originalValue)),
                controlModeId: Yup.string().when([], {
                  is: () => productReleases?.length > 0,
                  then: schema => schema
                    .required('Invalid cooking action')
                    .test('control-mode', 'Control mode must be selected', 
                      value => value !== undefined && value !== '')
                }),
            })
          );
        }
        return schema;
      }
    ),
  });
};

const createDefaultValues = (authorData, recipeId) => {
  const generateIds = () => ({
    sectionId: uuidv4(),
    instructionId: uuidv4(),
    cookingParameterId: uuidv4(),
  });

  const { sectionId, instructionId, cookingParameterId } = generateIds();

  return {
    metadata: {
      authorId: authorData?.userId || authorData?.id,
      authorName: authorData?.displayName,
      authorThumbnailUrl: authorData?.photoURL,
      title: "",
      recipeDescription: "",
      servings: 2,
      difficultyLevel,
      isVerifiedByKlynk: false,
      prepTimeInMins: null,
      cookTimeInMins: null,
      restTimeInMins: null,
      totalTimeInMins: 0,
      productReleases: [],
      courses: [],
      tools: [],
      diets: [],
      cuisines: [],
      tags: [],
      allergies: [],
      prepImage: "",
      notes: "",
      recipeUrl: "",
      recipeServeDescription: "",
      recipeIngredientsLength: 0,
      thumbnailUrl: "",
      sourceUrl: "",
      categories: [],
      recipeIngredientNames: [],
      smartFilters: [],
      scheduledTime: null,
      isPublished: false,
      isPublic: false,
      isCreatedByKlynk: false,
      id: recipeId,
      recipeId,
      isRecipeProSelected: false,
      isRikuSelected: false,
      isSemiSelected: false,
      isDraftRecipe: false,
      isProbeRequired: false,
    },
    recipeIngredients: [{
      id: uuidv4(),
      ingredientId: "",
      isScaleIngredient: false,
      name: "",
      quantity: null,
      quantityPerStep: null,
      ingredient: "",
      ingredientType: "",
      ingredientImage: "",
      prepStyles: "",
      units: "",
      unitsPerStep: "",
      loopingUnit: "",
      loopingQuantity: "",
      schedulingLimit: "",
      isOutput: false,
      isRecipeGenerated: false,
      isUserGenerated: false,
      loadingPosition: "",
      podType: "",
      podPosition: [],
      categoryInContextOfRiku: "",
      recipeId,
      sectionId,
      instructionId: "",
    }],
    recipeSections: [{
      id: sectionId,
      title: "",
      sectionType: "manual",
      sectionIndex: 1,
      isLoopSection: false,
      loopInstruction: "",
      recipeId,
      isProbeRequired: false,
    }],
    recipeInstructions: [{
      "@type": "HowToStep",
      id: instructionId,
      action: "",
      actions: [],
      mentionsTitle: "",
      recipeId,
      sectionId,
      tip: "",
      title: "",
      tools: [],
      instructionIndex: 1,
      stepImageUrl: "",
      stepVideoUrl: "",
      derivedIngredients: [],
    }],
    cookingParameters: [{
      id: cookingParameterId,
      sectionId,
      instructionId,
      recipeId,
      stirring: "",
      isLidOpen: false,
      action: "",
      actionId: "",
      powerInLevel: null,
      centralTemperatureInC: null,
      externalTemperatureInC: null,
      durationInMins: null,
      tempSensor: "",
      instructionIndex: 1,
      manualHeatLevel: "",
      ...cookingParameterConstant
    }],
  };
};

const useRecipeConstants = () => {
  const { user } = useAuth();
  const recipeId = uuidv4();
  const authorData = typeof user === "string" ? JSON.parse(user) : user;
  
  return {
    generateDefaultValue: createDefaultValues(authorData, recipeId),
    validationSchema: createValidationSchema(getPlainTextLength),
    userLogs: {
      userId: authorData?.userId || authorData?.id,
      userName: authorData?.displayName,
      createdAt: "",
      operationType: "",
      module: "recipes",
      diiferenceOfRecord: "",
    }
  };
};

export default useRecipeConstants;